body {
  /* https://mdbootstrap.com/support/react/modal-is-preventing-page-from-scrolling/ */
  overflow-y: scroll !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#videoview {
  position: relative;
  width: 640px;
  height: 480px;
}

#video {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1
}

#overlay {
  position: absolute;
  top: 100;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.outer {
  display: flex;
  align-items: center; 
  justify-content: center;
}
