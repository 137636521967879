.item {
    padding: 8px 0;
}

.card {
     background-color: #c8eeff; 
}

.stockCard {
    background-color: #a8e1fc; 
}